













import { PIXEL_RATIO } from '@/utils/constants'
import { TextLayerBuilder } from 'pdfjs-dist/lib/web/text_layer_builder'
import { EventBus } from '@/utils/event-bus'
import $ from 'jquery'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { PDFPageProxy, PDFRenderParams, PDFPageViewport } from 'pdfjs-dist'

@Component
export default class PDFPage extends Vue {
  @Prop() page?: PDFPageProxy
  @Prop() scale?: number
  @Prop() optimalScale?: number
  @Prop({ default: false }) isPageFocused!: boolean
  @Prop() isElementFocused?: boolean
  @Prop() pdfFindController?: any

  offsetLeft: number = 0
  reflowing: boolean = false
  viewport: any = null
  documentWidth: number = 0
  windowWidth: number = 0
  resizeTime: any = null
  resizeTimeout: boolean = false
  resizeDelta: number = 200
  originalWidth: number = 0
  originalHeight: number = 0
  renderTask: any = null
  textContent: any = null
  textLayer: any = null
  originalViewport: any = null

  get actualSizeViewport () {
    return this.viewport.clone({ scale: this.scale })
  }

  get canvasStyle () {
    const {
      width: actualSizeWidth,
      height: actualSizeHeight
    } = this.actualSizeViewport
    const [pixelWidth, pixelHeight] = [actualSizeWidth, actualSizeHeight].map(
      (dim: number) => Math.ceil(dim / PIXEL_RATIO)
    )
    return { width: `${pixelWidth}px`, height: `${pixelHeight}px` }
  }

  get canvasAttrs () {
    let { width, height } = this.viewport;
    [width, height] = [width, height].map((dim: number) =>
      Math.ceil(dim / PIXEL_RATIO)
    )
    const style = this.canvasStyle

    return {
      width,
      height,
      style
    }
  }

  get textLayerStyle () {
    let { width, height } = this.actualSizeViewport;
    [width, height] = [width, height].map((dim: number) =>
      Math.ceil(dim / PIXEL_RATIO)
    )
    const scaleX = width / this.originalWidth
    const scaleY = height / this.originalHeight
    const transform = `scale(${scaleX}, ${scaleY})`
    const origin = '0% 0%'
    return {
      width: this.originalWidth,
      height: this.originalHeight,
      transform,
      origin
    }
  }

  get pageNumber () {
    return (this.page as PDFPageProxy).pageNumber
  }

  focusPage () {
    // Line bellow commented out on purpose - not sure if there are negative consequences..
    // if (this.isPageFocused) return

    this.$emit('page-focus', this.pageNumber)
  }

  drawPage (force: boolean) {
    if (this.renderTask && !force) return
    // if (this.renderTask) {
    //   this.destroyRenderTask()
    // }

    const viewport: PDFPageViewport = (this.page as PDFPageProxy).getViewport({
      scale: this.optimalScale as number / PIXEL_RATIO
    })
    const canvasContext = (this.$el.querySelector('canvas') as HTMLCanvasElement).getContext('2d') as any
    canvasContext.width = this.viewport.width
    canvasContext.height = this.viewport.height
    const renderContext: PDFRenderParams = { canvasContext, viewport }
    this.renderTask = (this.page as PDFPageProxy).render(renderContext).promise
    // PDFPageProxy#render
    // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
    // this.renderTask = this.page.render(renderContext)
    this.renderTask
      .then(() => {
        return (this.page as PDFPageProxy).getTextContent()
      })
      .then((textContent: any) => {
        this.textContent = textContent
        this.drawTextLayer()

        this.$emit('page-rendered', {
          page: this.page,
          text: `Rendered page ${this.pageNumber}`
        })
      })
      .catch((response: any) => {
        this.destroyRenderTask(this.page)
        this.$emit('page-errored', {
          response,
          page: this.page,
          text: `Failed to render page ${this.pageNumber}`
        })
      })
  }

  drawTextLayer () {
    const viewport = (this.page as PDFPageProxy).getViewport({
      scale: this.optimalScale as number / PIXEL_RATIO
    })
    $((this.$el.querySelector('.text-layer') as HTMLCanvasElement)).empty()
    this.textLayer = new TextLayerBuilder({
      textLayerDiv: this.$el.querySelector('.text-layer'),
      eventBus: this.pdfFindController._eventBus,
      pageIndex: this.pageNumber - 1,
      viewport: viewport,
      findController: this.pdfFindController,
      enhanceTextSelection: true
    })

    // Set text-fragments
    this.textLayer.setTextContent(this.textContent)
    // Render text-fragments
    this.textLayer.render()

    this.positionTextLayer()

    this.reflowing = false
  }

  positionTextLayer () {
    this.$nextTick(() => {
      // update offset left property to position text layer (won't work as computed property)
      const totalWidth = $(this.$el)
        .find('.image-layer')
        .outerWidth(true)
      const innerWidth = $(this.$el)
        .find('.image-layer')
        .outerWidth()
      if (totalWidth && innerWidth) {
        this.offsetLeft = (totalWidth - innerWidth) / 2
      }
    })
  }

  updateVisibility () {
    this.drawTextLayer()

    // this.viewport = this.page.getViewport({ scale: this.optimalScale })
    // if (this.renderTask) {
    //   this.reflowing = true
    //   this.destroyPage()
    //   this.drawPage(true)
    // }
    // update offset left property to position text layer (won't work as computed property)
    this.$parent.$emit('update-visibility')
  }

  destroyPage (page: any) {
    page = page || this.page
    // PDFPageProxy#_destroy
    // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
    if (page) {
      page._destroy()
      // page.cleanup(true)
      this.destroyRenderTask(page)
    }
  }

  destroyRenderTask (page: any) {
    if (page.textLayerRenderTask && page.textLayerRenderTask.cancel) {
      page.textLayerRenderTask.cancel()
      delete page.textLayerRenderTask
    }
    if (page.renderTask && page.renderTask.cancel) {
      // RenderTask#cancel
      // https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
      page.renderTask.cancel()
      delete page.renderTask
    }
  }

  resizeEnd () {
    if ((new Date().getTime() - this.resizeTime) < this.resizeDelta) {
      setTimeout(this.resizeEnd, this.resizeDelta)
    } else {
      this.resizeTimeout = false
      this.windowWidth = window.innerWidth
      const {
        width: actualSizeWidth,
        height: actualSizeHeight
      } = this.actualSizeViewport
      const [pixelWidth] = [actualSizeWidth, actualSizeHeight].map((dim: number) => Math.ceil(dim / PIXEL_RATIO))
      this.documentWidth = pixelWidth
    }
  }

  created () {
    // PDFPageProxy#getViewport
    // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
    this.viewport = (this.page as PDFPageProxy).getViewport({ scale: this.optimalScale as number })

    this.originalViewport = (this.page as PDFPageProxy).getViewport({ scale: this.optimalScale as number })
    this.originalWidth = Math.ceil(this.originalViewport.width)
    this.originalHeight = Math.ceil(this.originalViewport.height)
    // const dispatchToDOM = {
    //   /** @type {boolean} */
    //   value: false,
    //   kind: 'viewer'
    // }
    EventBus.$on('render-all', () => {
      this.drawPage(false)
    })
    // window resize event watcher
    window.addEventListener('resize', () => {
      this.resizeTime = new Date().getTime()
      if (!this.resizeTimeout) {
        this.resizeTimeout = true
        setTimeout(this.resizeEnd, this.resizeDelta)
      }
    })
  }

  beforeDestroy () {
    this.destroyPage(this.page)
  }

  @Watch('scale')
  scaleChanged () {
    this.updateVisibility()
  }

  @Watch('page')
  pageChanged (_newPage: any, oldPage: any) {
    this.destroyPage(oldPage)
  }

  @Watch('isElementFocused')
  isElementFocusedChanged (isElementFocused: boolean) {
    if (isElementFocused) this.focusPage()
  }

  @Watch('windowWidth')
  windowWidthChanged () {
    this.positionTextLayer()
  }

  @Watch('documentWidth')
  documentWidthChanged (newWidth: any) {
    // emit width back up chain for header
    this.$emit('width', newWidth)
  }
}
