
// PDFDocument renders an entire PDF inline using
// PDF.js and <canvas>. Currently does not support,
// rendering of selected pages (but could be easily
// updated to do so).
import range from 'lodash/range'
import store from '@/store/store'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

const getDocument = (url: string) => {
  // Using import statement in this way allows Webpack
  // to treat pdf.js as an async dependency so we can
  // avoid adding it to one of the main bundles

  const opts = {
    httpHeaders: { Authorization: `Bearer ${store.getters.getToken}` },
    url: url,
    withCredentials: true
  }
  return import(
  /* webpackChunkName: 'pdfjs-dist' */
    'pdfjs-dist/webpack').then((pdfjs: any) => {
    const getDocumentHandler = pdfjs.getDocument(opts)
    return getDocumentHandler.promise
  })
}

// pdf: instance of PDFData
// see docs for PDF.js for more info
const getPages = (pdf: any, first: number, last: number) => {
  const allPages = range(first, last + 1).map((no: number) => pdf.getPage(no))
  return Promise.all(allPages)
}

const BUFFER_LENGTH = 1000
const getDefaults = () => {
  return {
    pages: [],
    cursor: 0
  }
}

@Component
export default class PDFData extends Vue {
  @Prop({ default: '' }) url!: string
  @Prop() pdfLinkService?: any
  @Prop() pdfFindController?: any

  pdf: any = null
  pages: any[] = []
  cursor: number = 0
  numPages: number = 0

  get pageCount () {
    return this.pdf ? this.pdf.numPages : 0
  }

  fetchPages (currentPage: number = 0) {
    if (!this.pdf) return
    if (this.pageCount > 0 && this.pages.length === this.pageCount) return

    const startIndex = this.pages.length
    if (this.cursor > startIndex) return

    const startPage = startIndex + 1
    const endPage = Math.min(Math.max(currentPage, startIndex + BUFFER_LENGTH), this.pageCount)
    this.cursor = endPage

    getPages(this.pdf, startPage, endPage)
      .then((pages: any[]) => {
        const deleteCount = 0
        this.pages.splice(startIndex, deleteCount, ...pages)
        return this.pages
      })
      .catch((response: any) => {
        this.$emit('document-errored', { text: 'Failed to retrieve pages', response })
      })
  }

  created () {
    // this.$on('page-rendered', this.onPageRendered)
    // this.$on('page-errored', this.onPageErrored)
    this.$on('pages-fetch', this.fetchPages)
  }

  mounted () {
    if (!this.url || !this.url.length) {
      return
    }

    this.$emit('show-doc-loader')
    getDocument(this.url)
      .then((pdf: any) => {
        setTimeout(() => {
          this.$emit('hide-doc-loader')
        }, 1200)
        this.pdf = pdf
      })
      .catch((response: any) => {
        this.$emit('hide-doc-loader')
        this.$emit('document-errored', { text: 'Failed to retrieve PDF', response })
      })
  }

  render (h: any) {
    if (!this.$scopedSlots.preview || !this.$scopedSlots.document) {
      return
    }
    return h('div', [
      this.$scopedSlots.preview({
        pages: this.pages
      }),
      this.$scopedSlots.document({
        pages: this.pages
      })
    ])
  }

  @Watch('url')
  urlChanged (url: string) {
    if (!url || !url.length) {
      return
    }

    this.$emit('show-doc-loader')
    getDocument(url)
      .then((pdf: any) => {
        setTimeout(() => {
          this.$emit('hide-doc-loader')
        }, 1200)
        this.pdf = pdf
      })
      .catch((response: any) => {
        this.$emit('hide-doc-loader')
        this.$emit('document-errored', { text: 'Failed to retrieve PDF', response })
      })
  }

  @Watch('pdf')
  pdfChanged (pdf: any, oldPdf: any) {
    if (!pdf) return
    if (oldPdf) Object.assign(this, getDefaults())

    this.$emit('page-count', this.pageCount)
    this.fetchPages()

    // set doc on various services:
    if (this.pdf) {
      if (this.pdfLinkService) {
        this.pdfLinkService.setDocument(this.pdf)
      }
      if (this.pdfFindController) {
        this.pdfFindController.setDocument(this.pdf)
      }
    }
  }
}
