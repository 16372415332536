





































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class PDFReturnToTop extends Vue {
  @Prop({ default: 0 }) currentPageNumber!: number;

  productKey: string = this.$route.params.productKey ?? ''
  gvdKey: string = this.$route.params.gvdKey ?? ''

  returnToTop () {
    this.$emit('page-focus', 1)
  }
}
