
















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// import visible from '@/directives/visible'

@Component
export default class PDFThumbnail extends Vue {
  @Prop() page?: any
  @Prop({ default: 1 }) scale!: number
  @Prop({ default: false }) isPageFocused!: boolean

  src: string = ''
  renderTask: any

  get viewport () {
    return this.page.getViewport({ scale: 1.0 })
  }

  get pageNumber () {
    return this.page.pageNumber
  }

  focusPage () {
    this.$emit('page-focus', this.pageNumber)
  }

  drawPage () {
    if (this.renderTask) return

    const { viewport } = this
    const canvas = document.createElement('canvas')
    const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D
    const renderContext = { canvasContext, viewport }
    canvas.height = viewport.height
    canvas.width = viewport.width

    this.renderTask = this.page.render(renderContext).promise
    this.renderTask
      .then(() => {
        this.src = canvas.toDataURL()

        // Zeroing the width and height causes Firefox to release graphics
        // resources immediately, which can greatly reduce memory consumption.
        canvas.width = 0
        canvas.height = 0
      })
      .then(() => {
        this.$emit('thumbnail-rendered', {
          page: this.page,
          text: `Rendered thumbnail ${this.pageNumber}`
        })
      })
      .catch((response: any) => {
        this.destroyRenderTask()
        this.$emit('thumbnail-errored', {
          response,
          page: this.page,
          text: `Failed to render thumbnail ${this.pageNumber}`
        })
      })
  }

  destroyPage (_newPage: any, page: any) {
    // PDFPageProxy#_destroy
    // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
    if (page) page._destroy()

    this.destroyRenderTask()
  }

  destroyRenderTask () {
    if (!this.renderTask || !this.renderTask.cancel) return

    // RenderTask#cancel
    // https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
    this.renderTask.cancel()
    delete this.renderTask
  }

  updateVisibility () {
    this.$parent.$emit('update-visibility')
  }

  beforeDestroy () {
    this.destroyPage(undefined, this.page)
  }

  @Watch('page')
  pageChanged () {
    this.destroyPage(undefined, undefined)
  }

  @Watch('src')
  srcChanged () {
    this.updateVisibility()
  }

  @Watch('scale')
  scaleChanged () {
    this.updateVisibility()
  }

  @Watch('isPageFocused')
  isPageFocusedChanged () {
    const { offsetTop, offsetHeight } = this.$el.parentElement as any
    (document.getElementById('pdfViewerPreview') as HTMLElement).scrollTop = offsetTop - (offsetHeight / 2)
    // (this.$el.parentElement as any).scrollIntoView(false, { behavior: `smooth` })
  }
}
