















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class PDFPaginator extends Vue {
  @Prop({ default: 1 }) value!: number
  @Prop({ default: 0 }) pageCount!: number

  input (event: Event) {
    let _targetPage = parseInt((event.target as any).value, 10)
    if (_targetPage < 1) {
      _targetPage = 1
    }
    if (_targetPage > this.pageCount) {
      _targetPage = this.pageCount
    }
    this.$emit('input', _targetPage)
    this.$emit('page-focus', _targetPage)
  }

  @Watch('pageCount')
  pageCountChanged () {
    this.$emit('input', 1)
  }
}
