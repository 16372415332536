













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { PDFFindBar } from 'pdfjs-dist/lib/web/pdf_find_bar'

@Component
export default class PDFFindControls extends Vue {
  findBar!: any

  @Prop()
  pdfFindController!: any

  @Watch('pdfFindController')
  pdfFindControllerChanged () {
    if (!this.pdfFindController) {
      return
    }
    const options = {
      bar: document.getElementById('findbar'),
      toggleButton: document.getElementById('viewFind'),
      findField: document.getElementById('findInput'),
      highlightAllCheckbox: document.getElementById('findHighlightAll'),
      caseSensitiveCheckbox: document.getElementById('findMatchCase'),
      entireWordCheckbox: document.getElementById('findEntireWord'),
      findMsg: document.getElementById('findMsg'),
      findResultsCount: document.getElementById('findResultsCount'),
      findPreviousButton: document.getElementById('findPrevious'),
      findNextButton: document.getElementById('findNext')
    }
    this.findBar = new PDFFindBar(options, this.pdfFindController._eventBus)
    // this.findBar.open()
    // bind events for findbar:
    this.pdfFindController._eventBus.on('find', this.webViewerFind)
    this.pdfFindController._eventBus.on('findbarclose', () => this.$emit('find-end'))
    this.pdfFindController._eventBus.on('updatefindmatchescount', this.webViewerUpdateFindMatchesCount)
    this.pdfFindController._eventBus.on('updatefindcontrolstate', this.webViewerUpdateFindControlState)
  }

  webViewerFind (evt: any) {
    this.$emit('find-start')
    this.pdfFindController.executeCommand('find' + evt.type, {
      query: evt.query,
      phraseSearch: evt.phraseSearch,
      caseSensitive: evt.caseSensitive,
      entireWord: evt.entireWord,
      highlightAll: evt.highlightAll,
      findPrevious: evt.findPrevious
    })
  }

  hiddenOnMobile () {
    const isMobile = /Mobi|iPad/i.test(navigator.userAgent)
    return isMobile ? 'mobile btn icon d-flex' : 'btn icon d-flex'
  }

  webViewerUpdateFindMatchesCount ({ matchesCount }: any) {
    this.findBar.updateResultsCount(matchesCount)
  }

  webViewerUpdateFindControlState ({ state, previous, matchesCount }: any) {
    if (matchesCount.total === 0) {
      this.$emit('find-end')
    }
    this.findBar.updateUIState(state, previous, matchesCount)
  }

  closeFindbar () {
    this.findBar.close()
  }
}
