









import { Component, Vue } from 'vue-property-decorator'
import { EventBus } from '@/utils/event-bus'

@Component
export default class PDFPrint extends Vue {
  renderComplete: boolean = false
  print () {
    if (this.renderComplete) {
      this.finishPrint()
      return
    }
    this.$bvModal
      .msgBoxConfirm('Please confirm that you wish to print. This could take a few minutes.', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        // need to render all pages (usually lazy loaded)
        EventBus.$emit('render-all')
        EventBus.$emit('show-loader', 10000)
        EventBus.$on('render-complete', () => {
          this.renderComplete = true
          EventBus.$off('render-complete')
          this.finishPrint()
        })
      })
  }

  finishPrint () {
    window.print()
    EventBus.$emit('hide-loader')
  }
}
