

import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { PDFPageProxy } from 'pdfjs-dist'

@Component
export default class ScrollingPage extends Vue {
  @Prop() page?: PDFPageProxy
  @Prop() focusedPage?: number
  @Prop() scrollTop?: number
  @Prop() clientHeight?: number
  @Prop() enablePageJump?: boolean

  elementTop: number = 0
  elementHeight: number = 0

  get isPageFocused () {
    return ((this.page as any)._pageIndex + 1) === this.focusedPage
  }

  get isElementFocused () {
    const { elementTop, bottom, elementHeight, scrollTop, clientHeight } = this
    if (!elementHeight) return

    const halfHeight = (elementHeight / 2)
    const halfScreen = ((clientHeight || 0) / 2)
    const navBar = document.getElementById('pdfViewerHeader')
    const delta = (elementHeight >= halfScreen ? halfScreen : halfHeight) - (navBar ? navBar.offsetHeight : 0)
    const threshold = (scrollTop || 0) + delta

    return elementTop < threshold && bottom >= threshold
  }

  get bottom () {
    return this.elementTop + this.elementHeight
  }

  get scrollBottom () {
    return (this.scrollTop || 0) + (this.clientHeight || 0)
  }

  jumpToPage () {
    if (!this.enablePageJump || this.isElementFocused || !this.isPageFocused) return

    this.$emit('page-jump', this.elementTop + 210) // + offset for header
  }

  updateElementBounds () {
    const { offsetTop, offsetHeight } = this.$el as any
    this.elementTop = offsetTop
    this.elementHeight = offsetHeight
  }

  @Watch('scrollTop')
  scrollTopChange () {
    this.updateElementBounds()
  }

  @Watch('clientHeight')
  clientHeightTopChange () {
    this.updateElementBounds()
  }

  @Watch('isPageFocused')
  isPageFocusedChange () {
    this.jumpToPage()
  }

  created () {
    this.$on('update-visibility', this.updateElementBounds)
  }

  mounted () {
    this.updateElementBounds()
  }

  render () {
    if (!this.$scopedSlots.default) {
      return
    }
    return this.$scopedSlots.default({
      isPageFocused: this.isPageFocused,
      isElementFocused: this.isElementFocused
    })
  }
}
