














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class PDFZoom extends Vue {
  @Prop({ default: 0.8 }) scale!: number
  increment: number = 0.25

  get isDisabled () {
    return !this.scale
  }

  zoomIn () {
    if (!this.scale || isNaN(this.scale)) {
      this.updateScale(1 + this.increment)
      return
    }
    this.updateScale(this.scale + this.increment)
  }

  zoomOut () {
    if (!this.scale || isNaN(this.scale)) {
      this.updateScale(1 - this.increment)
      return
    }
    if (this.scale <= this.increment) return
    this.updateScale(this.scale - this.increment)
  }

  updateScale (scale: number) {
    this.$emit('change', { scale })
  }

  fitWidth () {
    this.$emit('fit', 'width')
  }

  fitAuto () {
    this.$emit('fit', 'auto')
  }
}
