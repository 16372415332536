

















import { Component, Vue, Prop } from 'vue-property-decorator'
import ScrollingDocument from './ScrollingDocument.vue'
import PDFThumbnail from './PDFThumbnail.vue'

@Component({
  components: {
    ScrollingDocument,
    PDFThumbnail
  }
})
export default class PDFPreview extends Vue {
  @Prop({ default: [] }) pages!: any[]
  @Prop({ default: 0 }) pageCount!: number
  @Prop({ default: 0.5 }) scale!: number
  @Prop({ default: 1 }) currentPageNumber!: number
  @Prop({ default: false }) isPreviewEnabled!: boolean

  mounted () {
    this.$parent.$emit('pages-fetch', this.currentPageNumber)
  }

  onPagesFetch (currentPage: number) {
    this.$parent.$emit('pages-fetch', currentPage)
  }

  onPageFocused (pageNumber: number) {
    this.$parent.$emit('page-focus', pageNumber)
  }

  onThumbnailRendered (payload: any) {
    this.$el.dispatchEvent(new Event('scroll'))
    this.$parent.$emit('thumbnail-rendered', payload)
  }

  onThumbnailErrored (payload: any) {
    this.$parent.$emit('thumbnail-errored', payload)
  }
}
