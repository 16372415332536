
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ScrollingDocument from './ScrollingDocument.vue'
import PDFPage from './PDFPage.vue'
// PDFDocument renders an entire PDF inline using
// PDF.js and <canvas>. Currently does not support,
// rendering of selected pages (but could be easily
// updated to do so).
import { PIXEL_RATIO, VIEWPORT_RATIO } from '@/utils/constants'

import { EventBus } from '@/utils/event-bus'
import { PDFPageProxy } from 'pdfjs-dist'

@Component({
  components: {
    ScrollingDocument,
    PDFPage
  }
})
export default class PDFDocument extends Vue {
  @Prop({ default: [] }) pages!: PDFPageProxy[]
  @Prop({ default: 0 }) pageCount!: number
  @Prop({ default: 0.75 }) scale!: number
  @Prop({ default: 4 }) optimalScale!: number
  @Prop() fit?: string
  @Prop({ default: 1 }) currentPageNumber!: number
  @Prop({ default: false }) isPreviewEnabled!: boolean
  @Prop() pdfFindController?: any

  renderedCount: number = 0

  get defaultViewport () {
    if (!this.pages.length) return { width: 0, height: 0 }
    const [page] = this.pages

    return page.getViewport({ scale: 1.0 })
  }

  get isPortrait () {
    const { width, height } = this.defaultViewport
    return width <= height
  }

  pageWidthScale () {
    const { defaultViewport, $el } = this
    if (!defaultViewport.width) return 0

    return ($el.clientWidth * PIXEL_RATIO) * VIEWPORT_RATIO / defaultViewport.width
  }

  pageHeightScale () {
    const { defaultViewport, $el } = this
    if (!defaultViewport.height) return 0

    return ($el.clientHeight * PIXEL_RATIO) * VIEWPORT_RATIO / defaultViewport.height
  }

  // Determine an ideal scale using viewport of document's first page, the pixel ratio from the browser
  // and a subjective scale factor based on the screen size.
  fitWidth () {
    const scale = this.pageWidthScale()
    this.updateScale(scale, { isOptimal: !this.optimalScale })
  }

  fitHeight () {
    const scale = this.isPortrait ? this.pageHeightScale() : this.pageWidthScale()
    this.updateScale(scale)
  }

  fitAuto () {
    const scale = Math.min(this.pageWidthScale(), this.pageHeightScale())
    this.updateScale(scale)
  }

  updateScale (scale: number, { isOptimal = false }: any = {}) {
    if (!scale) return
    this.$emit('scale-change', { scale, isOptimal })
  }

  onPageJump (scrollTop: number) {
    (document.getElementById('app') as HTMLElement).scrollTop = scrollTop // triggers 'scroll' event
    // this.$el.scrollTop = scrollTop // triggers 'scroll' event
  }

  onPagesFetch (currentPage: number) {
    this.$parent.$emit('pages-fetch', currentPage)
  }

  onPageFocused (pageNumber: number) {
    this.$parent.$emit('page-focus', pageNumber)
  }

  onPageRendered (payload: any) {
    this.$parent.$emit('page-rendered', payload)
    this.renderedCount++
    if (this.renderedCount === 1) { // first page rendered
      this.$parent.$emit('document-rendered')
    }
    if (this.renderedCount === this.pageCount) { // first page rendered
      EventBus.$emit('render-complete')
    }
  }

  onPageErrored (payload: any) {
    this.$parent.$emit('page-errored', payload)
  }

  @Watch('fit')
  fitChanged (fit: string) {
    switch (fit) {
      case 'width':
        this.fitWidth()
        break
      case 'auto':
        this.fitAuto()
        break
      default:
        break
    }
  }

  @Watch('pageCount')
  pageCountChanged () {
    this.fitWidth()
  }

  @Watch('isPreviewEnabled')
  isPreviewEnabledChanged () {
    this.fitWidth()
  }
}
