




































import PDFDocument from '@/components/pdfviewer/PDFDocument.vue'
import PDFData from '@/components/pdfviewer/PDFData.vue'
import PDFPreview from '@/components/pdfviewer/PDFPreview.vue'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    PDFDocument,
    PDFData,
    PDFPreview
  }
})
export default class PDFViewer extends Vue {
  @Prop() url!: string
  @Prop({ default: 0.75 }) scale!: number
  @Prop({ default: 4 }) optimalScale!: number
  @Prop() fit?: string
  @Prop({ default: 1 }) currentPageNumber!: number
  @Prop() pageCount?: number
  @Prop({ default: false }) isPreviewEnabled!: boolean
  @Prop() pdfFindController?: any
  @Prop() pdfLinkService?: any
  @Prop({ default: false }) searchActive!: boolean

  width: number = 0
  windowWidth: number = 0
  resizeTime: any = null
  resizeTimeout: any = false
  resizeDelta: number = 200
  loaderActive: boolean = true

  floor (value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0)
    return Math.floor(value * multiplier) / multiplier
  }

  onDocumentRendered () {
    this.$emit('document-rendered', this.url)
  }

  onDocumentErrored (e: any) {
    this.$emit('document-errored', e)
  }

  updateScale ({ scale, isOptimal }: any) {
    this.$emit('scale-change', { scale, isOptimal })
  }

  updateCurrentPage (pageNumber: number) {
    this.$emit('page-focus', pageNumber)
  }

  updatePageCount (pageCount: number) {
    this.$emit('page-count', pageCount)
  }

  resizeEnd () {
    if ((new Date().getTime() - this.resizeTime) < this.resizeDelta) {
      setTimeout(this.resizeEnd, this.resizeDelta)
    } else {
      this.resizeTimeout = false
      this.windowWidth = window.innerWidth
    }
  }

  updateWidth (newWidth: number) {
    this.width = newWidth
    this.$emit('width', this.width)
  }

  showLoader () {
    this.loaderActive = true
  }

  hideLoader () {
    this.loaderActive = false
  }

  created () {
    window.addEventListener('resize', () => {
      this.resizeTime = new Date()
      if (!this.resizeTimeout) {
        this.resizeTimeout = true
        setTimeout(this.resizeEnd, this.resizeDelta)
      }
    })
  }

  @Watch('windowWidth')
  windowWidthChanged (newWidth: number) {
    this.$emit('width', newWidth < this.width ? newWidth : this.width)
  }
}
