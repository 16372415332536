




















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// import visible from '@/directives/visible'
import ScrollingPage from './ScrollingPage.vue'
import { PDFPageProxy } from 'pdfjs-dist'

@Component({
  components: {
    ScrollingPage
  }
})
export default class ScrollingDocument extends Vue {
  @Prop() pages?: PDFPageProxy[]
  @Prop() enablePageJump?: boolean
  @Prop({ default: 1 }) currentPageNumber!: number
  @Prop({ default: true }) isParentVisible!: boolean

  focusedPage: any = -1
  scrollTop: number = 0
  clientHeight: number = 0

  get pagesLength () {
    return this.pages ? this.pages.length : 0
  }

  created () {
    (document.getElementById('app') as HTMLElement).addEventListener('scroll', this.updateScrollBounds)
  }

  beforeDestroy () {
    (document.getElementById('app') as HTMLElement).removeEventListener('scroll', this.updateScrollBounds)
  }

  fetchPages (currentPage: any) {
    this.$emit('pages-fetch', currentPage)
  }

  onPageJump (scrollTop: number) {
    this.$emit('page-jump', scrollTop)
  }

  updateScrollBounds () {
    const { scrollTop, clientHeight } = document.getElementById('app') as HTMLElement
    this.scrollTop = scrollTop
    this.clientHeight = clientHeight
  }

  @Watch('isParentVisible')
  isParentVisibleChanged () {
    this.updateScrollBounds()
  }

  @Watch('pagesLength')
  pagesLengthChanged (count: number, oldCount: number) {
    if (oldCount === 0) this.$emit('pages-reset')

    // Set focusedPage after new pages are mounted
    this.$nextTick(() => {
      this.focusedPage = this.currentPageNumber
    })
  }

  @Watch('currentPageNumber')
  currentPageNumberChanged (currentPageNumber: number) {
    if (currentPageNumber > this.pagesLength) {
      this.fetchPages(currentPageNumber)
    } else {
      this.focusedPage = currentPageNumber
    }
  }
}
